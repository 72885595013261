let appName = '手机云管家';

var u = navigator.userAgent;

var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
  appName = '手机云管家'
} else if (isAndroid) {
  appName = '手机云管家'
}

const acountData={
  'cn':[
    {
      'topTitle':'账号注销须知',
    },
    {
      'title':'一、注销账号的后果',
      'content':[
        '您可以通过隐私政策列明的联系方式联系我们要求注销账户或在我们的产品中直接申请注销账号。 敬请您注意并知悉，您提交注销申请后，我们需要10天时间处理您的账户注销。如您在10天内没有再次使用该注销账号登录，我们将在10天后清除您的账号信息，完成注销，如果您在10天内进行了此账号的登录，则视为您主动放弃此次注销操作的申请。当您注销账号后，将（可能）产生包括但不限于如下后果：',
        '1.注销账号后，您将无法登录、使用该账号及登录手机云管家 App使用相关服务，即您将无法再以此账号登录、使用手机云管家 App相关产品服务。',
        '2.注销账号后，您曾通过该账号登录、使用的手机云管家 App相关产品服务下的所有内容、信息、数据、记录将会被全部删除，您也无法再检索、访问、获取、继续使用和找回这些信息或数据，包括但不限于：',
        '(1)该账号下的您自主设置的个人资料（如：头像、密码）；',
        '(2)该账号下您主动提供或在使用过程中授权我们收集的个人信息（如：绑定的账号、邮箱等）；',
        '(3)其他与该账号相关的内容、信息、数据、历史记录或服务记录等。',
        '3.注销账号后，您在使用手机云管家 App产品与服务期间产生的账号内资产和虚拟权益将视为自动放弃，无法予以退还或退换，包括但不限于：',
        '(1)您在手机云管家 App产品与服务中充值的各类会员费用；',
        '(2)您在手机云管家 App产品与服务中充值的各类会员身份权益。',
        '4.账号注销具有不可逆转和不可撤销性，账号一旦注销完成，将无法恢复，您的原账号对应的数字ID将可能分配给其他新注册的用户。如果您在注销后以相同手机号码或相同电子邮箱再次向我们申请账号，此时该账号将默认为新的用户账号。',
        '5.账号一旦注销，您将不再享有您与我们签署的相关用户协议、其他权利义务性文件中约定的适用于注册用户的权利，但法律法规、规章、规范性文件，政府的政策、命令等另有要求或用户服务协议中另有规定的除外。',
      ],
    },
    {
      'title':'二、注销须满足的条件',
      'content':[
        '在您向我们申请注销账号前，为保障您的账号安全和财产权益，您的账号需同时满足以下条件：',
        '1.该账号是您通过我们的官方渠道注册的账号，且为您本人注册、使用的账号；',
        '2.该账号内无未处理完毕的交易或已提供服务但未支付的订单或服务；'
      ]
    },
    {
      'title': '三、其他须知',
      'content': [
        '1.注销申请提交后，您将不能再通过该账号使用手机云管家 App的产品及服务。我们将在10天内处理您的注销申请，并通过电子邮件或短信向您发送注销成功或未能注销的通知。我们的客服团队也有可能通过您留下的联系方式就注销申请的结果与您联络。',
        '2.注销账号并不减轻或免除您应根据相关法律法规、相关协议、规则（可能）需要承担的相关责任。',
        '3.如在账号注销过程中有任何问题您无法处理的或在处理过程中有任何疑惑的，可联系官方邮箱（cleanphone.feedback@gmail.com）协助处理。',
      ]
    }
  ],
  'en':[
    {
      'topTitle':'Delete Account Notification',
    },
    {
      'title':'1. The consequences of canceling the account',
      'content':[
        'You can contact us through the contact information of privacy policies to ask us to cancel your account or apply directly to our products. Please pay attention and know that after you submit the cancellation application, we need 10 days to handle your account to cancel it. If you do not use the cancellation account login again within 10 days, we will remove your account information in 10 days and complete the cancellation. If you log in within 10 days Application. When you log out of the account, the (possibly) is included but not limited to the following consequences:',
        '1.1 After canceling the account, you will not be able to log in, use the account and log in to the mobile cloud housekeeper app to use related services, that is, you will no longer log in to this account and use the mobile cloud housekeeper APP related product services.',
        '1.2 After canceling the account, you have logged in to and used all the contents, information, data, and records under the mobile phone cloud housekeeper APP-related product services that you used. And retrieve this information or data, including but not limited to:',
        'A. The personal data (such as avatar, password) under the account; ',
        'B. In this account, you actively provide or authorize our personal information (such as bound account, mailbox, etc.);',
        'C. Other content, information, data, historical records, or service records related to the account.',
        '1.3 After canceling the account, the assets, and virtual rights in the account generated during the use of the mobile cloud housekeeper APP product and service will be deemed to be automatically abandoned and cannot be refunded or returned, including but not limited to:',
        '(1) Various membership fees you recharge in the mobile cloud housekeeper APP products and services;',
        '(2) All kinds of member identity rights are recharged in the mobile cloud housekeeper APP products and services. ',
        '1.4 Account cancellation is irreversible and irrevocable. Once the account is canceled, it will not be restored. The number ID corresponding to your original account will be assigned to other newly registered users. If you apply for an account again with the same mobile phone number or the same email after cancellation, the account will be the default as a new user account at this time.',
        '1.5 Once the account is canceled, you will no longer enjoy the rights agreed in the relevant user agreement and other rights and obligations that you signed with us, but laws, regulations, regulations, regulatory documents, government policies, and orders Except for other requirements or user service agreements.',
      ],
    },
    {
      'title':'2. The condition that the cancellation must be met',
      'content':[
        'Before you apply for a cancellation of your account with us, to ensure your account security and property rights, your account must meet the following conditions at the same time:',
        '2.1 This account is an account registered through our official channels, and the account number registered and used for you;',
        '2.2 There are no unprecedented transactions in this account or orders or services that have been provided but not paid;'
      ]
    },
    {
      'title': '3. Other notes',
      'content': [
        '3.1 After the cancellation of the application, you will not be able to use the product and service of the mobile cloud housekeeper app through this account. We will handle your cancellation application within 10 days and send you a notice of cancellation or failure to cancel via email or text messages. Our customer service team may also contact you through the results of the contact information that you leave.',
        '3.2 The cancellation account is not alleviated or exempted from the relevant liability that you need to bear in accordance with relevant laws and regulations, related agreements, and rules (possible).',
        '3.3 If you have any questions in the process of cancellation or have any doubts if you cannot handle it or have any doubts during the processing process, you can contact the official mailbox (cleanphone.feedback@gmail.com) to assist in processing.',
      ]
    }
  ]
}
export { acountData };
